import { EventApi } from '@fullcalendar/core';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { FBaseEvent, DetailedEvent } from '../../models/calendar';
import { ActionCreator, CdAction } from '../../../redux/utils';
import { Holiday } from '../../sagas/CalendarControlSagas';
import { SelectedCalendar } from '../filters';
import { CalendarSubscriptionPayload } from '../../../shared/services/CalendarSubscriptionService';
import { CalendarState } from '..';

import {
  CalendarEventColoringCriteria,
  CalendarViewState,
  ResourceType,
  TimePeriod,
} from '.';

export type ChangeViewActionType = CdAction<string>;
export const CHANGE_VIEW = 'calendar/changeView';
export const ChangeViewAction: ActionCreator<string> = (
  targetView: string
) => ({
  type: CHANGE_VIEW,
  payload: targetView,
});

export type ChangeTimePeriodActionType = CdAction<TimePeriod>;
export const CHANGE_TIME_PERIOD = 'calendar/changeTimePeriod';
export const ChangeTimePeriodAction: ActionCreator<TimePeriod> = (period) => ({
  type: CHANGE_TIME_PERIOD,
  payload: period,
});

export type ChangeResourceTypeActionType = CdAction<ResourceType>;
export const CHANGE_RESOURCE_TYPE = 'calendar/changeResourceType';
export const ChangeResourceTypeAction: ActionCreator<ResourceType> = (
  resourceType
) => ({
  type: CHANGE_RESOURCE_TYPE,
  payload: resourceType,
});

export type GoToDateActionType = CdAction<Date>;
export const GO_TO_DATE = 'calendar/goToDate';
export const GoToDateAction: ActionCreator<Date> = (date) => ({
  type: GO_TO_DATE,
  payload: date,
});

export interface ChangeEventArgs {
  oldEvent: EventApi;
  newEvent: EventApi;
  revert?: () => void;
}

export type UpdateEventAction = CdAction<ChangeEventArgs>;
export const RESIZE_EVENT = 'calendar/resizeEvent';
export const UpdateEventAction: ActionCreator<ChangeEventArgs> = (args) => ({
  type: RESIZE_EVENT,
  payload: args,
});

export type FeedType =
  | 'absences'
  | 'categories'
  | 'user'
  | 'resource'
  | 'iCal'
  | 'parish'
  | 'all';

export type AddEventAction = CdAction<DetailedEvent>;
export const ADD_EVENT = 'calendar/AddEvent';
export const AddEvent: ActionCreator<DetailedEvent> = (event) => ({
  type: ADD_EVENT,
  payload: event,
});

export type GoToWeekAction = CdAction<Date>;
export const GO_TO_WEEK = 'calendar/GoToWeek';
export const GoToWeekAction: ActionCreator<Date> = (start: Date) => ({
  type: GO_TO_WEEK,
  payload: start,
});

export type LoadNationalHolidaysSuccess = CdAction<Holiday[]>;
export const LOAD_NATIONAL_HOLIDAYS_SUCCESS_TYPE =
  'calendar/LoadNationalHolidaysSuccess';
export const LoadNationalHolidaysSuccess: ActionCreator<Holiday[]> = (
  nationalHolidays: Holiday[]
) => ({
  type: LOAD_NATIONAL_HOLIDAYS_SUCCESS_TYPE,
  payload: nationalHolidays,
});

export type LoadLiturgicalHolidaysSuccess = CdAction<Holiday[]>;
export const LOAD_LITURGICAL_HOLIDAYS_SUCCESS_TYPE =
  'calendar/LoadLiturgicalHolidaysSuccess';
export const LoadLiturgicalHolidaysSuccess: ActionCreator<Holiday[]> = (
  liturgicalHolidays: Holiday[]
) => ({
  type: LOAD_LITURGICAL_HOLIDAYS_SUCCESS_TYPE,
  payload: liturgicalHolidays,
});

export type ToggleShowNationalHolidays = CdAction<undefined>;
export const TOGGLE_SHOW_NATIONAL_HOLIDAYS =
  'calendar/ToggleShowNationalHolidays';
export const ToggleShowNationalHolidays: ActionCreator<undefined> = () => ({
  type: TOGGLE_SHOW_NATIONAL_HOLIDAYS,
});

export type ToggleCollections = CdAction<undefined>;
export const TOGGLE_SHOW_COLLECTIONS = 'calendar/ToggleCollections';
export const ToggleShowCollections: ActionCreator<undefined> = () => ({
  type: TOGGLE_SHOW_COLLECTIONS,
});

export type ToggleShowLiturgicalHolidays = CdAction<undefined>;
export const TOGGLE_SHOW_LITURGICAL_HOLIDAYS =
  'calendar/ToggleShowLiturgicalHolidays';
export const ToggleShowLiturgicalHolidays: ActionCreator<undefined> = () => ({
  type: TOGGLE_SHOW_LITURGICAL_HOLIDAYS,
});

export type UpdateCalendarStateAction = CdAction<CalendarState>;

export type SetCalendarEventColoringCriteria =
  CdAction<CalendarEventColoringCriteria>;
export const SET_CALENDAR_EVENT_COLORING_CRITERIA =
  'calendar/SetCalendarEventColoringCriteria';
export const SetCalendarEventColoringCriteria: ActionCreator<
  CalendarEventColoringCriteria
> = (criteria: CalendarEventColoringCriteria) => ({
  type: SET_CALENDAR_EVENT_COLORING_CRITERIA,
  payload: criteria,
});

export type InitializeViewState = CdAction<Partial<CalendarViewState>>;
export const INITIALIZE_VIEW_STATE = 'calendar/InitializeViewState';
export const InitializeViewState: ActionCreator<Partial<CalendarViewState>> = (
  criteria: Partial<CalendarViewState>
) => ({
  type: INITIALIZE_VIEW_STATE,
  payload: criteria,
});

export type EditEvent = CdAction<FBaseEvent>;
export const EDIT_EVENT = 'calendar/EditEvent';
export const EditEvent: ActionCreator<FBaseEvent> = (event: FBaseEvent) => ({
  type: EDIT_EVENT,
  payload: event,
});

export const useEditEvent = () => {
  const dispatch = useDispatch();
  return useCallback(
    () => (event: FBaseEvent) => {
      dispatch(EditEvent(event));
    },
    [dispatch]
  );
};

export type DeleteEvent = CdAction<FBaseEvent>;
export const DELETE_EVENT = 'calendar/DeleteEvent';
export const DeleteEvent: ActionCreator<FBaseEvent> = (event: FBaseEvent) => ({
  type: DELETE_EVENT,
  payload: event,
});

export const useDeleteEvent = () => {
  const dispatch = useDispatch();
  return useCallback(
    () => (event: FBaseEvent) => dispatch(DeleteEvent(event)),
    [dispatch]
  );
};

export const TOGGLE_SHOW_RESOURCES = 'calendar/ToggleShowResources';
export const toggleShowResources: ActionCreator<undefined> = () => ({
  type: TOGGLE_SHOW_RESOURCES,
});

export const useToggleShowResources = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleShowResources()), [dispatch]);
};

export const TOGGLE_SHOW_PREP_TIME = 'calendar/ToggleShowPrepTime';
export const toggleShowPrepTime: ActionCreator<undefined> = () => ({
  type: TOGGLE_SHOW_PREP_TIME,
});

export const useToggleShowPrepTime = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleShowPrepTime()), [dispatch]);
};

export const TOGGLE_SHOW_INTENTIONS = 'calendar/ToggleShowIntentions';
export const toggleShowIntentions: ActionCreator<undefined> = () => ({
  type: TOGGLE_SHOW_INTENTIONS,
});

export const useToggleShowIntentions = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleShowIntentions()), [dispatch]);
};

export const TOGGLE_SHOW_DECLINED_EVENTS = 'calendar/ToggleShowDeclinedEvents';
export const toggleShowDeclinedEvents: ActionCreator<undefined> = () => ({
  type: TOGGLE_SHOW_DECLINED_EVENTS,
});

export const useToggleShowDeclinedEvents = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleShowDeclinedEvents()), [dispatch]);
};

export const TOGGLE_RESOURCE_VIEW = 'calendar/ToggleResourceView';
export const toggleResourceView: ActionCreator<boolean | undefined> = (
  payload?: boolean
) => ({
  type: TOGGLE_RESOURCE_VIEW,
  payload,
});

export const useToggleResourceView = () => {
  const dispatch = useDispatch();
  return useCallback(
    (payload?: boolean) => dispatch(toggleResourceView(payload)),
    [dispatch]
  );
};

export const SET_UNSELECTED_CALENDARS = 'calendar/setUnselectedCalendars';
export const setUnselectedCalendars: ActionCreator<SelectedCalendar[]> = (
  unselectedCalendars
) => ({
  type: SET_UNSELECTED_CALENDARS,
  payload: unselectedCalendars,
});
export const FETCH_CALENDAR_SUBSCRIPTIONS =
  'calendar/fetchCalendarSubscriptions';
export const FetchCalendarSubscriptionAction: ActionCreator<undefined> =
  () => ({
    type: FETCH_CALENDAR_SUBSCRIPTIONS,
  });

export const CALENDAR_SUBSCRIPTION_CREATE =
  'calendar/createCalendarSubscriptions';
export const CreateCalendarSubscriptionAction: ActionCreator<
  CalendarSubscriptionPayload
> = (calendarSubscription) => ({
  type: CALENDAR_SUBSCRIPTION_CREATE,
  payload: calendarSubscription,
});

export type FetchCalendarSubscriptionSuccess = CdAction<
  CalendarSubscriptionPayload[]
>;
export const FETCH_CALENDAR_SUBSCRIPTIONS_SUCCESS =
  'calendar/fetchCalendarSubscriptionsSuccess';
export const FetchCalendarSubscriptionSuccessAction: ActionCreator<
  CalendarSubscriptionPayload[]
> = (calendarSubscriptions) => ({
  type: FETCH_CALENDAR_SUBSCRIPTIONS_SUCCESS,
  payload: calendarSubscriptions,
});

export const CALENDAR_SUBSCRIPTION_DELETE =
  'calendar/fetchCalendarSubscriptionDelete';
export const DeleteCalendarSubscriptionAction: ActionCreator<string> = (
  id
) => ({
  type: CALENDAR_SUBSCRIPTION_DELETE,
  payload: id,
});

export const CALENDAR_SUBSCRIPTION_FORCE_SYNC =
  'calendar/forceResyncCalendarSubscription';
export const ForceSyncCalendarSubscriptionAction: ActionCreator<string> = (
  id
) => ({
  type: CALENDAR_SUBSCRIPTION_FORCE_SYNC,
  payload: id,
});

export const CALENDAR_SUBSCRIPTION_UPDATE =
  'calendar/fetchCalendarSubscriptionUpdate';
export const UpdateCalendarSubscriptionAction: ActionCreator<
  CalendarSubscriptionPayload
> = (calendarSubscription) => ({
  type: CALENDAR_SUBSCRIPTION_UPDATE,
  payload: calendarSubscription,
});
